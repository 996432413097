import { WppPill } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useEffect, useState } from 'react'

import { useGetChats } from 'api/brief/queries/chats/useGetChats'
import { useGetMessages } from 'api/brief/queries/chats/useGetMessages'
import { useGetTaskImage } from 'api/brief/queries/chats/useGetTaskImage'
import { Flex } from 'components/common/flex/Flex'
import { ChatProvider } from 'contexts/ChatContext'
import { useAssistant } from 'hooks/useAssistant'
import { useChatContext } from 'hooks/useChatContext'
import { MessagesWithGatheredData } from 'types/chats/chat'

import styles from './Brief.module.scss'
import BriefAssistant from './BriefAssistant'
import BriefPreview from './BriefPreview'

export function Brief() {
  const { taskId, messagesWithGatheredData } = useChatContext()
  const { saveBrief } = useAssistant({ activeTaskId: taskId })
  const { data: taskImage, isLoading: isLoadingTaskImage } = useGetTaskImage({ params: { taskId }, enabled: !!taskId })

  if (isLoadingTaskImage) {
    return <div>Loading...</div>
  }

  return (
    <div style={{ height: '100%' }}>
      <Flex className={styles.flexHeight100} direction="row" gap={8}>
        <BriefAssistant />
        <div className={styles.verticalDivider} />
        <BriefPreview />
      </Flex>
      <Flex style={{ marginTop: 20 }} direction="row" justify="end" align="center">
        <div>
          <WppPill
            disabled={!messagesWithGatheredData.data?.requirementsFulfilled}
            onClick={() => {
              if (!messagesWithGatheredData.data?.requirementsFulfilled) {
                return
              }
              saveBrief({
                file: taskImage?.[0]?.url,
                data: messagesWithGatheredData.data?.gatheredData,
              }).then((val: any) => {
                console.log('response', val)
                window.parent.postMessage({ type: 'message', closeBriefModal: true }, '*')
              })
            }}
            className={styles.pillBlack}
          >
            Confirm & Publish
          </WppPill>
        </div>
      </Flex>
    </div>
  )
}

export default function BriefContainer() {
  const [activeTaskId, setActiveTaskId] = useState(localStorage.getItem('activeTaskId') || '')
  const { osContext } = useOs()
  const tenantId = osContext.tenant.id
  const { id: userId } = osContext.userDetails

  const {
    data: chats,
    isLoading: isLoadingChats,
    isError: isErrorChats,
  } = useGetChats({
    params: { userId, tenantId, taskId: activeTaskId || '' },
    enabled: !!activeTaskId && !!userId && !!tenantId,
  })

  const {
    data: messages = {} as MessagesWithGatheredData,
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
  } = useGetMessages({
    params: { taskId: activeTaskId || '', chatId: chats?.[0]?.chat.id || '', page: 1, itemsPerPage: 100 },
    enabled: !!chats?.[0]?.chat.id,
  })

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return
      }

      if (event.data.type === 'message') {
        setActiveTaskId(event.data.activeTaskId)
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  if (isLoadingChats || isLoadingMessages) {
    return <div>Loading...</div>
  }

  if (isErrorChats || isErrorMessages) {
    return <div>Something went wrong...</div>
  }

  return (
    <ChatProvider
      value={{
        chatsWithGatheredData: chats,
        isLoadingChats,
        messagesWithGatheredData: messages,
        isLoadingMessages,
        chatId: chats?.[0]?.chat.id || '',
        taskId: activeTaskId || '',
      }}
    >
      <Brief />
    </ChatProvider>
  )
}
