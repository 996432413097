// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jd9aj{--header-height: 68px;padding:0px 32px 32px 32px;display:flex;flex-direction:column;justify-content:space-between;gap:20px;height:calc(100% - 20px)}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,qBAAA,CACA,0BAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,QAAA,CACA,wBAAA","sourcesContent":[".briefContainer {\n  --header-height: 68px;\n  padding: 0px 32px 32px 32px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 20px;\n  height: calc(100% - 20px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"briefContainer": `Jd9aj`
};
export default ___CSS_LOADER_EXPORT___;
